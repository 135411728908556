export default function SVGACFilter() {
  return (
    <svg width="130" height="174" viewBox="0 0 296 174" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_37_152)">
        <rect x="20" y="20" width="256" height="134" rx="16.8466" fill="url(#paint0_linear_37_152)" />
        <path
          d="M95.2996 110.467L85.6559 120.049L76.0875 110.467L80.3819 106.2V97.4421H71.1149V106.2L75.4094 110.467L65.7657 120.049L56.1973 110.467L60.4917 106.2V67.5746L76.3888 51.9297L91.0051 66.4518V106.2L95.2996 110.467ZM80.3819 86.4383V72.7397L71.1149 63.5324V86.4383H80.3819Z"
          fill="white"
        />
        <path
          d="M128.75 67.2758L120.688 75.1357L109.236 63.533V102.832L112.024 105.527L120.688 96.8439L128.449 104.404L112.627 120.049L98.6133 106.201V66.4524L113.38 51.8555L128.75 67.2758Z"
          fill="white"
        />
        <path
          d="M154.442 84.6654H162.434L139.782 116.016L147.247 94.6257L147.711 93.2986H146.305H138.113L149.896 66.4005H159.787L153.506 83.3198L153.007 84.6654H154.442Z"
          stroke="white"
          strokeWidth="1.9963"
        />
        <path
          d="M202.056 107.547L189.625 120.047H175.385L167.023 111.514L171.543 107.097V64.954L166.947 60.3878L175.385 52.0039H189.625L202.056 64.5049V107.547ZM191.433 101.559V70.4933L182.166 61.5855V110.466L191.433 101.559Z"
          fill="white"
        />
        <path
          d="M239.803 67.2758L231.742 75.1357L220.29 63.533V102.832L223.078 105.527L231.742 96.8439L239.502 104.404L223.681 120.049L209.667 106.201V66.4524L224.434 51.8555L239.803 67.2758Z"
          fill="white"
        />
        <line x1="56.2754" y1="128.826" x2="128.826" y2="128.826" stroke="white" strokeWidth="2.45935" />
      </g>
      <defs>
        <filter
          id="filter0_d_37_152"
          x="0.869648"
          y="0.869648"
          width="294.261"
          height="172.261"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="5.97824" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_37_152" />
          <feOffset />
          <feGaussianBlur stdDeviation="6.57606" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.639216 0 0 0 0 0.188235 0 0 0 0 0.611765 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_37_152" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_37_152" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_37_152"
          x1="34.7561"
          y1="20"
          x2="344.797"
          y2="145.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF18D5" />
          <stop offset="1" stopColor="#7600D1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
